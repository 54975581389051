$primary-color: #1c3136;

// Navbar
.bg-primary {
    background-color: $primary-color !important;
}
.navbar-toggler {
    border: 0;
}
.navbar-brand .logo {
    margin-left: 10px;
}
.navbar-dark .navbar-nav .nav-link {
    background: transparent !important;
    border: 0;
    color: white;
}

// Modals 
.modal {
    .nav-link {
        color: $primary-color !important;
    }
    .modal-header {
        background-color: $primary-color !important;
        color: white;
    
        .modal-title {
            font-weight: 400;
        }
        .close {
            color: white;
            text-shadow: none;
            opacity: 1;
        }
    }
    .modal-body {
        min-height: 0;
    }
    a {
        color: $primary-color !important;
    }
    
}
#enter-something {
    .no {
        margin-right: 0.5rem;
    }
}


// Buttons 
.text-primary {
    color: #1c3136 !important;
}
.btn-outline-primary {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    border: 0;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    color: #fff;
    background-color: #1c3136;
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle, button:focus:not(:focus-visible), .btn-outline-primary.focus, .btn-outline-primary:focus {
    color: #fff;
    background-color: $primary-color;
    outline: none;
    box-shadow: none;
}
*:focus {
    outline: none;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none;
}

// Inputs
.custom-select:focus {
    outline: none;
    box-shadow: none;
    border-color: $primary-color;
}
.checkbox {
    input[type=checkbox] {
        accent-color: $primary-color;
        margin-right: 0.5rem !important;
    }
}
.form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: $primary-color;
}

// Settings modal
#settings {
    select, .form-control {
        margin-bottom: 0.5rem;
    }

    .card-header-tabs {
        margin-top: 1rem;
    }

    .slider-input {
        input[type=range] {
            accent-color: $primary-color !important;
        }
    }
}

// Templates
#site-user-templates-list {
    margin-top: 0.5rem;
}

#site-templates-list, #site-user-templates-list {
    .title {
        background: white !important;
    }
    .delete {
        background: white !important;
    }
}

// Compiler overrides
#overrides-selection {
    .overrides-results-col {
        .card {
            margin-bottom: 0.5rem;
            
            .card-header {
                display: flex;
                align-items: center;

                .override-name {
                    flex: 1;
                }
                .override {
                    flex: 1;
                }
            }
        }
    }
}
